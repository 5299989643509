<template>
	<el-date-picker v-model="modelValue" value-format="timestamp" placeholder="选择日期时间" v-bind="$attrs"></el-date-picker>
</template>
<script>
export default {
	props:{
		value:"",
	},
	computed:{
		modelValue:{
			get(){
				return this.value ? this.value*1000 : this.value;
			},
			set(value){
				this.$emit('input',value ? value / 1000 : value)
			}
		}
	}
}
</script>
