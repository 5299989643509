<template>
	<main>
		<el-select class="el-block" v-model="modelValue" clearable filterable remote default-first-option :multiple="multiple" :placeholder="placeholder" :remote-method="remoteMethod" :loading="loading" :disabled="disabled">
			<el-option v-for="(item,index) in options" :key="index" :label=getLabel(item) :value="item[id]"></el-option>
		</el-select>
	</main>
</template>

<script >
export default {
	props:{
		value:"",
		api:"",
		limit:{
			type:Number,
			default:20,
		},
		id:{
			default:'id',
			type:String,
		},
		title:{
			default:`title`,
			type:String,
		},
		render:{
			default:'',
			type:String,
		},
		placeholder:{
			type:String,
			default:'搜索'
		},
		multiple:{
			type:Boolean,
			default:false,
		},
		disabled:{
			type:Boolean,
			default:false,
		},
		query:{
			type:Object,
			default:()=>{
				return {}
			}
		}
	},
	data(){
		return {
			options: [],
			list: [],
			loading: false,
		}
	},
	computed:{
		modelValue:{
			get(){
				return this.value;
			},
			set(value){
				this.$emit('input',value)
			}
		}
	},
	watch:{
		modelValue(n,o){
			this.remoteMethod()
		}
	},
	mounted() {
		this.remoteMethod('',this.multiple ? this.value?.join(',') : this.value);
	},
	methods:{
		getLabel(item){
			if(this.render){
				let text = this.render;
				for(let o in item){
					text = text.replace(`{${o}}`,item[o]);
				}
				return text;
			}else{
				return  item[this.title]
			}
		},
		remoteMethod(query = '',id = '') {
			this.loading = true;
			const params = {
				[this.title]:query,
				limit:this.limit
			};
			if(id){
				params[this.id] = id;
			}
			const data = Object.assign({},this.query,params)
			this.api(data).then((res)=>{
				this.options = res.list;
				this.$forceUpdate();
			}).finally(()=>{
				this.loading = false;
			})
		}
	}
}
</script>
