<template>
	<div class="ckeditor">
		<div :id="id"></div>
		<div class="image-choose" id="image-choose" @click="imageChoose"></div>
		<components-attachment v-if="show_attachment" :accept="accept_list" @close="show_attachment = false" @choose="choose"/>
	</div>
</template>

<script >
import {uploadFile} from "@/plugins/ckeditor/upload";
import ComponentsAttachment from "@/components/admin/form/upload/Attachment";
import {ext_config} from "@/config";
import {createUUID} from "@/common/string";
export default {
	components:{ComponentsAttachment},
	props:{
		value:"",
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data(){
		return {
			editor:"",
			show_attachment:false,
		}
	},
	computed:{
		id(){
			return "ckeditor-"+createUUID();
		},
		accept_list(){
			return ext_config.image;
		},
		modelValue:{
			get(){
				return this.value
			},
			set(value){
				this.$emit('input',value)
			}
		}
	},
	watch:{
		modelValue(n){
			if(this.value && this.editor && !this.editor.getData()){
				this.editor.setData(this.value);
			}
		}
	},
	mounted() {
		this.$nextTick(()=>{
			this.initCkeditor()
		})
	},
	methods:{
		imageChoose(){
			this.show_attachment = true;
		},
		choose(url){
			this.show_attachment = false;
			this.editor.model.change((writer)=>{
				const position = this.editor.model.document.selection.getFirstPosition();
				writer.insertElement( 'imageInline', { src :url}, position);
			})
		},
		setValue(value){
			this.$emit('input',value)
		},
		initCkeditor(){
			ClassicEditor.create(document.querySelector('#'+this.id)).then(editor => {
				const body = editor.ui.view.body._bodyCollectionContainer
				body.remove()
				editor.ui.view.element.appendChild(body)

				editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
					return new uploadFile(loader,1200);
				}
				editor.model.document.on('change:data', () => {
					this.setValue(editor.getData())
				});
				if (this.disabled) {
					editor.enableReadOnlyMode(this.id)
				}
				this.editor = editor;
			}).catch(error => {
				console.error(error);
			})
		}
	},
	beforeDestroy() {
		this.editor.setData("")
	}
}
</script>

<style lang="scss" scoped>
.ckeditor{overflow: hidden;}
</style>
